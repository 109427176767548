import React from "react";
import {
  RealEstateAgentInfo,
  SellerInfo,
  EscrowInfo,
  OrderFormNhdReport,
  ReportSelectionInfo,
  Modal
} from "components";
import MESSAGE from "helpers/Message";
import {
  RealEstateAgentModel,
  SellerInfoModel,
  EscrowInfoModel,
  ReportSelectionInfoModel,
  OrderFormNhdReportModel,
  EmailToModel,
  CountyType,
  RealEstateAgentDetails,
  EscrowInfoDetails,
  FormFieldModel
} from "components/Models";
import "components/formFields/FormFields.css";
import "./css/OrderForm.css";
import { CleanPhone, disableControls, FormatPhoneNumber } from "helpers/Utils";
import { IsFormValid, scrollIntoView } from "helpers/Validation";
import defaultOrderFormData from "./DefaultOrderFormData/DefaultOrderFormData";
import LocalStorageHelper from "helpers/LocalStorageHelper";
import { counties as Counties } from "helpers/Counties";
import { OrderDataService } from "api";
import Address from "../components/confirmAddress/Models/address";
import { loggedInEmail } from "api/services/authService";
import { TrackGAEvent } from "../components/gaTracking/GATracking";
import ModalChildren from "../components/modalChildren/ModalChildren";
import ERROR_MESSAGES from "../helpers/ErrorMessages";
import ReportType from "../enums/ReportType";
import OrderDetails from "../api/Models/OrderDetails";
import ConfigHelper from "helpers/ConfigHelper";
import PromoCodeModel from '../components/reportSelectionInfo/Models/PromoCodeModel';
import { PATHS } from 'helpers/Constants';
import UltimateBundleSelectionModal from "../components/modalChildren/UltimateBundleSelectionModal";
import { isTodayLessOrEqualToDate } from "helpers/Utils";

interface IProps {
  location: any;
}

class OrderForm extends React.Component<IProps> {
  defaultRealEstate: RealEstateAgentModel = {
    real_estate_agent: MESSAGE.real_estate_agent,
    saved_agents: MESSAGE.saved_agents,
    agent_name_label: MESSAGE.agent_name,
    enter_first_name: MESSAGE.enter_first_name,
    enter_last_name: MESSAGE.enter_last_name,
    agent_company: MESSAGE.agent_company,
    enter_company_name: MESSAGE.enter_company_name,
    agent_company_address: MESSAGE.real_estate_company_address,
    enter_agent_company_street_address: MESSAGE.enter_street_address,
    agent_company_state: MESSAGE.state,
    agent_company_state_name: MESSAGE.state_name,
    agent_company_city: MESSAGE.city,
    enter_agent_company_city: MESSAGE.enter_city,
    enter_agent_company_zip: MESSAGE.enter_zip,
    agent_company_zip_code: MESSAGE.zip_code,
    agent_phone_number: MESSAGE.agent_phone_number,
    enter_phone_number: MESSAGE.enter_phone_number,
    agent_email: MESSAGE.agent_email,
    enter_email_address: MESSAGE.enter_email_address,
    sale_by_owner: MESSAGE.sale_by_owner
  };
  defaultSellerInfo: SellerInfoModel = {
    seller_information: MESSAGE.seller_information,
    seller_name: MESSAGE.seller_name,
    seller_phone: MESSAGE.seller_phone,
    seller_email: MESSAGE.seller_email,
    enter_full_name: MESSAGE.enter_full_name,
    enter_phone_number: MESSAGE.enter_phone_number,
    enter_email_address: MESSAGE.enter_email_address
  };
  defaultEscrowInfo: EscrowInfoModel = {
    escrow_information: MESSAGE.escrow_information,
    do_you_have_escrow_information: MESSAGE.do_you_have_escrow_information,
    escrow_details: MESSAGE.escrow_details,
    enter_first_name: MESSAGE.enter_first_name,
    enter_last_name: MESSAGE.enter_last_name,
    enter_address: MESSAGE.enter_address,
    enter_phone_number: MESSAGE.enter_phone_number,
    enter_company_name: MESSAGE.enter_company_name,
    enter_street_address: MESSAGE.enter_street_address,
    enter_state: MESSAGE.enter_state,
    state: MESSAGE.state,
    city: MESSAGE.city,
    enter_city: MESSAGE.enter_city,
    state_name: MESSAGE.state_name,
    enter_zip: MESSAGE.enter_zip,
    zip_code: MESSAGE.zip_code,
    enter_escrow_number: MESSAGE.enter_escrow_number,
    escrow_officer: MESSAGE.escrow_officer,
    saved_officers: MESSAGE.saved_officers,
    escrow_officer_email: MESSAGE.escrow_officer_email,
    enter_email: MESSAGE.enter_email,
    escrow_officer_number: MESSAGE.escrow_officer_number,
    escrow_company_name: MESSAGE.escrow_company_name,
    escrow_company_address: MESSAGE.escrow_company_address,
    escrow_number: MESSAGE.escrow_number,
    estimated_closing_date: MESSAGE.estimated_closing_date,
    month: MESSAGE.month,
    month_name: MESSAGE.month_name,
    year: MESSAGE.year,
    years: MESSAGE.years,
    save_officer_for_future_order: MESSAGE.save_officer_for_future_order
  };

  // This should be removed.  This is completely superfluous code
  defaultReportSelectionInfo: ReportSelectionInfoModel = {
    report_selection: MESSAGE.report_selection,
    report_heading_one: MESSAGE.report_heading_one,
    price_one: MESSAGE.price_one,
    report_heading_two: MESSAGE.report_heading_two,
    price_two: MESSAGE.price_two,
    bundle_text: MESSAGE.bundle_text,
    bundle_price: MESSAGE.bundle_price,
    bundle_coverage_text: MESSAGE.bundle_coverage_text,
    bundle_details: MESSAGE.bundle_details,
    get_more_info: MESSAGE.get_more_info,
    this_report_exclusively: MESSAGE.this_report_exclusively,
    delivery_report_to: MESSAGE.delivery_report_to,
    order_confirmation: MESSAGE.order_confirmation,
    escrow_invoice: MESSAGE.escrow_invoice,
    add_another_report: MESSAGE.add_another_report,
    include_special_details_about_your_order:
      MESSAGE.include_special_details_about_your_order,
    delivery_message: MESSAGE.delivery_message,
    ca_civil_code: MESSAGE.ca_civil_code,
    add_another_recipient: MESSAGE.add_another_recipient,
    promotional_price: MESSAGE.promotional_price,
    bundle_additional_text: MESSAGE.bundle_additional_text,
  };
  defaultOrderFormNhdReport: OrderFormNhdReportModel = {
    nhd_report_for: MESSAGE.nhd_report_for,
    address: MESSAGE.address,
    nhd_report_address: new Address(),
    county: MESSAGE.county,
    report_county: MESSAGE.report_county_default,
    property_apn: MESSAGE.property_apn,
    property_apn_value: MESSAGE.property_apn_value,
    order_report: MESSAGE.order_report,
    changeAddress: MESSAGE.change,
    changeApn: MESSAGE.change
  };

  state: {
    defaultRealEstateDetails?: RealEstateAgentDetails;
    defaultEscrowDetails?: EscrowInfoDetails;
    userType: string;
    isFormValid: boolean;
    isSubmitted: boolean;
    fieldValues: FormFieldModel;
    recipientEmails: EmailToModel[];
    escrowAgentDetails: any;
    isConfirmationError: boolean;
    otherRecipients: EmailToModel[];
    hasSubmissionError: boolean;
    promoCodeInfo: PromoCodeModel;
    showUltimateBundleGetInfo: boolean;
    showUltimateBundleSelections: boolean;
    ubpPropertyType: string;
    ubpPropertySize: string;
    ubpAddOns: string[];
    ubpBuyerName: string;
    ubpBuyerPhone: string;
    ubpWarrantyType: string;
    disabledFormDate: boolean
  };

  isSubmitLocked = false

  constructor(props: any) {
    super(props);

    var lsAPN = localStorage.getItem(LocalStorageHelper.NHD_APN) || "";
    var lsCounty = localStorage.getItem(LocalStorageHelper.NHD_COUNTY) || "";
    var lsAddress = localStorage.getItem(LocalStorageHelper.NHD_ADDRESS) || "";
    var address = new Address();
    if (lsAddress.trim().length > 0) {
      address = JSON.parse(lsAddress) as Address;
    }

    var selectedCountyNum = parseInt(lsCounty);

    this.defaultOrderFormNhdReport.report_county =
      (Counties.find(
        county => county.getValue() === selectedCountyNum
      ) as CountyType) || MESSAGE.report_county_default;
    this.defaultOrderFormNhdReport.property_apn_value = lsAPN;
    this.defaultOrderFormNhdReport.nhd_report_address = address;

    this.state = {
      userType: "",
      isFormValid: false,
      isSubmitted: false,
      fieldValues: defaultOrderFormData,
      recipientEmails: [
        {
          recipientID: 0,
          email: loggedInEmail(),
          isReadOnly: true,
          fieldName: "email0",
          isOrderConfirmDisabled: true,
          isOrderConfirm: true
        }
      ],
      escrowAgentDetails: null,
      isConfirmationError: false,
      otherRecipients: [],
      hasSubmissionError: false,
      promoCodeInfo: {},
      showUltimateBundleGetInfo: false,
      showUltimateBundleSelections: false,
      ubpPropertyType: "",
      ubpPropertySize: "",
      ubpAddOns: [],
      ubpBuyerName: "",
      ubpBuyerPhone: "",
      ubpWarrantyType: "",
      disabledFormDate: isTodayLessOrEqualToDate(ConfigHelper.OrderFormDisableDate)
    };
  }

  logFailedOrder = () => {
    TrackGAEvent('Service', 'Failed', 'Unable to place order');
  };

  handleOrderError = () => {
    this.logFailedOrder();
    this.showOrderErrorModal();
  };

  showOrderErrorModal = () => {
    this.setState({ hasSubmissionError: true });
  };

  closeErrorModal = (e: any) => {
    this.setState({ hasSubmissionError: false });
  };

  handleManualApnChangeWithUnit = () => {
    const { fieldValues } = this.state;
    const address = this.defaultOrderFormNhdReport.nhd_report_address;

    if (address.unitNumber !== "" && address.unitNumber.length > 0) {
      fieldValues.manualApnChange.value = true;
      this.setState(fieldValues);
    }
  };

  componentDidMount() {
    var lsUserDetails = localStorage.getItem(LocalStorageHelper.NHD_USERDETAILS) || "";

    if (lsUserDetails.trim().length > 0) {
      var userDetails = JSON.parse(lsUserDetails);
      var accountType = userDetails.accountType;

      if (accountType) {
        this.setState({ userType: accountType });
        const { fieldValues } = this.state;
        fieldValues["recipientEmail-0"].value = loggedInEmail();

        if (accountType === "Real Estate Agent") {
          fieldValues.agentFirstName.value = userDetails.firstName;
          fieldValues.agentLastName.value = userDetails.name;
          fieldValues.agentCompany.value = userDetails.companyName;
          fieldValues.agentCompanyAddress.value = userDetails.companyAddress.street;
          fieldValues.agentCompanyCity.value = userDetails.companyAddress.city;
          fieldValues.agentCompanyState.value = "CA";
          fieldValues.agentCompanyZipCode.value = userDetails.companyAddress.zip;
          fieldValues.agentPhoneNumber.value = FormatPhoneNumber(
            userDetails.phoneNumber
          );
          fieldValues.agentPhoneNumber.preValidateField = true;
          fieldValues.email1.value = loggedInEmail();
        } else if (accountType === "Closing") {
          this.setState({ escrowAgentDetails: userDetails });
          this.SetEscrowInfo();
        } else if (accountType === "seller") {
          const recipientEmails = [
            {
              recipientID: 1,
              email: loggedInEmail() ? loggedInEmail() : "Seller",
              isReadOnly: true,
              fieldName: "email2",
              isOrderConfirmDisabled: true,
              isOrderConfirm: true
            }
          ];
          fieldValues["recipientEmail-0"].value = loggedInEmail()
            ? loggedInEmail()
            : "Seller";
          this.setState({ recipientEmails });
        }

        this.setState({ fieldValues });
      } else {
        this.setState({ userType: "" });
      }
    }

    if (this.state.disabledFormDate) {
      const container = document.querySelector(".disable-order-form-with-opacity");
      disableControls(container);
    }
  }

  SetEscrowInfo() {
    const { fieldValues, escrowAgentDetails } = this.state;
    if (escrowAgentDetails) {
      fieldValues.escrowOfficerFirstName.value = escrowAgentDetails.firstName;
      fieldValues.escrowOfficerLastName.value = escrowAgentDetails.name;
      fieldValues.email3.value = escrowAgentDetails.email;
      fieldValues.escrowOfficerNumber.value = FormatPhoneNumber(escrowAgentDetails.phoneNumber);
      fieldValues.escrowCompanyName.value = escrowAgentDetails.companyName;
      fieldValues.escrowCompanyAddress.value =
        escrowAgentDetails.companyAddress.street;
      fieldValues.city.value = escrowAgentDetails.companyAddress.city;
      fieldValues.state.value = escrowAgentDetails.companyAddress.state;
      fieldValues.zipCode.value = escrowAgentDetails.companyAddress.zip;
      this.setState({ fieldValues });
    }
  }

  onHspChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { fieldValues } = this.state;
    fieldValues.isHspSelected.value = e.target.checked;
    this.setState({ fieldValues });
  }

  onApplyPromocodeHandler = () => {
    const { fieldValues } = this.state;

    if (fieldValues.promoCode.value && fieldValues.taxReportRadio.value) {
      const reportType = fieldValues.taxReportRadio.value.trim() === "NHD Tax & Environmental Report" ?
        ReportType.NhdTaxEnvironment :
        ReportType.NhdTax;
      const promoCode = fieldValues.promoCode.value;

      OrderDataService.ValidatePromoCode(promoCode, reportType)
        .then((voucherCode: string) => {
          let promoCodeInfo: PromoCodeModel = {}

          if (voucherCode == promoCode) {
            promoCodeInfo.promocode_valid = true;
            promoCodeInfo.promocode_message = "Valid Promo Code";
          }
          else {
            promoCodeInfo.promocode_valid = false;
            promoCodeInfo.promocode_message = "Invalid Promo Code";
          }

          this.setState({ promoCodeInfo });
        });
    }
  }

  //TODO This should be refactored.  Having a single change handler is too cumbersome for a form this size
  onChangeHandler = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    datePickerObject?: any
  ) => {
    this.setState({ isConfirmationError: false });
    const { recipientEmails, fieldValues, otherRecipients } = this.state;
    const fieldName = event ? event.target.name : datePickerObject.name;
    let fieldValue = event ? event.target.value : datePickerObject.value;
    const fieldKeyValue: FormFieldModel = {};
    let updatedRecipient: FormFieldModel = {};

    if (event && event.target.type === "checkbox") {
      fieldKeyValue[fieldName] = {
        ...fieldValues[fieldName],
        value: event.target["checked"]
      };
    }
    else if (event && event.target.type === "select-one") {
      //validation for 'agentCompanyState' dropdown field
      if (fieldName === this.state.fieldValues.agentCompanyState.name) {
        fieldValue = (fieldValue === "Select State") ? "" : fieldValue
      }

      fieldKeyValue[fieldName] = {
        ...fieldValues[fieldName],
        value: fieldValue
      };
    }
    else {
      fieldKeyValue[fieldName] = {
        ...fieldValues[fieldName],
        value: fieldValue
      };
    }

    if (fieldName === "taxReportRadio") {
      if (fieldValue === MESSAGE.bundle_text) {

        fieldValues.sellerName.required = true;
        fieldValues.sellerPhone.required = true;
        fieldValues.email2.required = true;

      } else {

        fieldValues.sellerName.required = false;
        fieldValues.sellerPhone.required = false;
        fieldValues.email2.required = false;

        if (fieldValues.escrowYesNo.value == "No") {
          fieldValues.escrowClosingDate.value = "";
        }
      }
    }

    if (fieldName === "escrowYesNo") {

      let preserveClosingDate = fieldValues.taxReportRadio.value == MESSAGE.bundle_text

      if (fieldValue === "No") {
        recipientEmails.map(recipient => {
          if (recipient.fieldName === "email3") {
            recipient.email = "";
          }
          return recipient;
        });
        this.setState({ recipientEmails });

        // Set required to false while hiding the Escrow Component
        fieldValues.savedOfficers = { ...fieldValues.savedOfficers, value: "" };
        fieldValues.escrowOfficerFirstName = {
          ...fieldValues.escrowOfficerFirstName,
          value: "",
          required: false
        };
        fieldValues.escrowOfficerLastName = {
          ...fieldValues.escrowOfficerLastName,
          value: "",
          required: false
        };
        fieldValues.email3 = {
          ...fieldValues.email3,
          value: "",
          required: false
        };
        fieldValues.escrowOfficerNumber = {
          ...fieldValues.escrowOfficerNumber,
          value: "",
          required: false
        };
        fieldValues.escrowCompanyName = {
          ...fieldValues.escrowCompanyName,
          value: "",
          required: false
        };
        fieldValues.escrowCompanyAddress = {
          ...fieldValues.escrowCompanyAddress,
          value: "",
          required: false
        };
        fieldValues.city = { ...fieldValues.city, value: "", required: false };
        fieldValues.state = {
          ...fieldValues.state,
          value: "",
          required: false
        };
        fieldValues.zipCode = {
          ...fieldValues.zipCode,
          value: "",
          required: false
        };
        fieldValues.escrowNumber = {
          ...fieldValues.escrowNumber,
          value: "",
          required: false
        };
        fieldValues.escrowClosingDate = {
          ...fieldValues.escrowClosingDate,
          value: preserveClosingDate ? fieldValues.escrowClosingDate.value : ""
        };
      } else {
        // Set required to true while displaying the Escrow Component
        fieldValues.savedOfficers = { ...fieldValues.savedOfficers, value: "" };
        fieldValues.escrowOfficerFirstName = {
          ...fieldValues.escrowOfficerFirstName,
          value: "",
          required: true
        };
        fieldValues.escrowOfficerLastName = {
          ...fieldValues.escrowOfficerLastName,
          value: "",
          required: true
        };
        fieldValues.email3 = {
          ...fieldValues.email3,
          value: "",
          required: true
        };
        fieldValues.escrowOfficerNumber = {
          ...fieldValues.escrowOfficerNumber,
          value: "",
          required: true
        };
        fieldValues.escrowCompanyName = {
          ...fieldValues.escrowCompanyName,
          value: "",
          required: true
        };
        fieldValues.escrowCompanyAddress = {
          ...fieldValues.escrowCompanyAddress,
          value: "",
          required: false
        };
        fieldValues.city = { ...fieldValues.city, value: "", required: false };
        fieldValues.state = { ...fieldValues.state, value: "CA", required: false };
        fieldValues.zipCode = {
          ...fieldValues.zipCode,
          value: "",
          required: false
        };
        fieldValues.escrowNumber = {
          ...fieldValues.escrowNumber,
          value: "",
          required: false
        };
        fieldValues.escrowClosingDate = {
          ...fieldValues.escrowClosingDate,
          value: preserveClosingDate ? fieldValues.escrowClosingDate.value : ""
        };
      }

      this.SetEscrowInfo();
    }

    if (event && event.target.type === "email") {
      let isNewRecipient = true;
      let updatedRecipientList: any[] = [];

      //TODO These Names should be changed.  No reason to use email#
      //email0 -> logged in user
      //email1 -> realestate agent
      //email2 -> seller
      //email3 -> escrow
      if (fieldName === "escrowYesNo") {
        this.setState({ escrowYesNo: fieldValue });
        if (fieldValue === "No") {
          recipientEmails.map(recipient => {
            if (recipient.fieldName === "email3") {
              recipient.email = "";
            }
            return recipient;
          });
          this.setState({ recipientEmails });
        }
        return;
      } else if (
        (fieldName === "email1" &&
          this.state.userType === "Real Estate Agent") ||
        (fieldName === "email3" &&
          this.state.userType.toLowerCase() === "escrow")
      ) {
        return;
      } else if (
        fieldName === "email2" &&
        this.state.userType.toLowerCase() === "seller" &&
        (!fieldValue || fieldValue === "")
      ) {
        fieldValue = "Seller";
      }

      recipientEmails.forEach(recipient => {
        if (recipient.fieldName === fieldName) {
          recipient.email = fieldValue;
          isNewRecipient = false;
          var originalRecipient = Object.keys(fieldValues)
            .filter(field => fieldValues[field].fieldName === fieldName)
            .map(field => fieldValues[field]);
          if (originalRecipient.length === 1) {
            originalRecipient[0].value = fieldValue;
            updatedRecipient = originalRecipient[0];
          }
        }
        if (recipient.email && recipient.email !== "")
          updatedRecipientList.push(recipient);
      });

      otherRecipients.forEach(recipient => {
        if (recipient.fieldName === fieldName) {
          recipient.email = fieldValue;
          isNewRecipient = false;
        }
      });

      if (isNewRecipient) {
        let deliveryReportEmail: EmailToModel;
        deliveryReportEmail = {
          recipientID: recipientEmails.length + 1,
          email: fieldValue,
          isReadOnly: true,
          isTextField: true,
          fieldName
        };
        updatedRecipientList.push(deliveryReportEmail);

        fieldKeyValue[`recipientEmail-${recipientEmails.length + 1}`] = {
          name: `recipientEmail-${recipientEmails.length + 1}`,
          value: fieldValue,
          type: "email",
          fieldName
        };
        fieldKeyValue[
          `recipientOrderConfirmationCheckbox-${recipientEmails.length + 1}`
        ] = {
          name: `recipientOrderConfirmationCheckbox-${recipientEmails.length +
            1}`,
          value: true
        };
      }

      updatedRecipientList.sort((field1, field2) => {
        if (!field1.fieldName || !field2.fieldName) return 0;
        if (field1.fieldName > field2.fieldName) {
          return 1;
        }
        if (field2.fieldName > field1.fieldName) {
          return -1;
        }
        return 0;
      });

      this.setState({
        recipientEmails: updatedRecipientList,
        otherRecipients
      });
    } else if (event && event.target.type === "tel") {
      fieldKeyValue[fieldName].value = FormatPhoneNumber(fieldValue);
    }

    this.setState({
      fieldValues: { ...fieldValues, ...fieldKeyValue, ...updatedRecipient }
    });
  };

  submitHandler = (event: React.FormEvent<HTMLFormElement>, ubpContinue: boolean = false) => {
    event?.preventDefault();
    this.setState({ isSubmitted: true, isConfirmationError: false });
    const { fieldValues } = this.state;

    if (fieldValues.taxReportRadio.value === MESSAGE.bundle_text) {
      if (fieldValues.sellerPhone.value || fieldValues.email2.value) {
        fieldValues.sellerPhone.required = false;
        fieldValues.email2.required = false;
      }
      fieldValues.promoCode.value = "ULTNHD1";
    }

    console.log('Submit Handler:', fieldValues);

    let continueWithSubmit = false;

    if (IsFormValid(fieldValues) && !this.isSubmitLocked) {
      if (fieldValues.taxReportRadio.value === MESSAGE.bundle_text) {
        if (!ubpContinue) {
          this.setState({ showUltimateBundleSelections: true });
        } else {
          continueWithSubmit = true;
        }
      } else {
        continueWithSubmit = true;
      }
    }

    if (continueWithSubmit) {

      TrackGAEvent("selection", "success", fieldValues.taxReportRadio.value);
      const recipientsDetails = [...this.state.otherRecipients, ...this.state.recipientEmails];
      recipientsDetails.forEach(recipient => {
        recipient.isOrderConfirm = fieldValues[`recipientOrderConfirmationCheckbox-${recipient.recipientID}`].value
      });

      if (!this.isSubmitLocked) {
        this.isSubmitLocked = true;

        let orderDetails = this.prepareRequest(
          fieldValues,
          this.defaultOrderFormNhdReport.property_apn_value,
          this.defaultOrderFormNhdReport.nhd_report_address,
          this.defaultOrderFormNhdReport.report_county,
          recipientsDetails,
          this.state.ubpPropertyType,
          this.state.ubpPropertySize,
          this.state.ubpAddOns,
          this.state.ubpBuyerName,
          this.state.ubpBuyerPhone,
          this.state.ubpWarrantyType
        );

        OrderDataService.SubmitOrder(orderDetails).then(response => {
          if (response) {
            localStorage.setItem(LocalStorageHelper.NHD_ORDER_ID, response);
            //TODO We should consider setting up a redirect component or using history.push rather than this
            //Didn't refactor this now due to time
            let pathWithBaseFolderPath = ConfigHelper.GetBasePath() + PATHS.OrderConfirmation
            window.location.replace(pathWithBaseFolderPath);
          } else {
            this.handleOrderError();
            this.isSubmitLocked = false;
          }
        });
      }
    }
    else if (!this.isSubmitLocked) {
      scrollIntoView(fieldValues);
    }
  };

  prepareRequest = (orderDetails: FormFieldModel,
    apn: string,
    address: Address,
    county: CountyType,
    extraEmails: Array<EmailToModel>,
    ubpPropertyType: string,
    ubpPropertySize: string,
    ubpHspAddons: string[],
    ubpBuyerName: string,
    ubpBuyerPhone: string,
    ubpWarrantyType: string,
  ): OrderDetails => {
    return {
      APN: apn || " ",
      customerID: 18,// ID for AHS report
      countyNum: county.countyNum,
      street: address.streetAddress || " ",
      city: address.city || " ",
      state: address.state || " ",
      zip: address.zipCode || " ",
      agentFirstName: orderDetails.agentFirstName.value,
      agentLastName: orderDetails.agentLastName.value,
      agentCompany: orderDetails.agentCompany.value,
      agentAddress: orderDetails.agentCompanyAddress.value,
      agentCity: orderDetails.agentCompanyCity.value,
      agentState: orderDetails.agentCompanyState.value,
      agentZip: orderDetails.agentCompanyZipCode.value,
      agentPhone: CleanPhone(orderDetails.agentPhoneNumber.value),
      agentEmail: orderDetails.email1.value,
      sellerName: orderDetails.sellerName.value,
      sellerPhone: CleanPhone(orderDetails.sellerPhone.value),
      sellerEmail: orderDetails.email2.value,
      useEscrow: orderDetails.escrowYesNo.value === "Yes",
      escrowFirst: orderDetails.escrowOfficerFirstName.value,
      escrowLast: orderDetails.escrowOfficerLastName.value,
      escrowEmail: orderDetails.email3.value,
      escrowCompanyName: orderDetails.escrowCompanyName.value,
      escrowCompanyAddress: orderDetails.escrowCompanyAddress.value,
      escrowCity: orderDetails.city.value,
      escrowState: orderDetails.state.value === "Select State" ? "" : orderDetails.state.value,
      escrowZIP: orderDetails.zipCode.value,
      escrowPhone: CleanPhone(orderDetails.escrowOfficerNumber.value),
      escrowNumber: orderDetails.escrowNumber.value,
      reportType: orderDetails.taxReportRadio.value.trim() === MESSAGE.report_heading_two.trim() ?
        ReportType.NhdTax :
        ReportType.NhdTaxEnvironment,
      recipients: extraEmails.map(email => {
        return {
          email: email.email || '',
          confirmation: email.isOrderConfirm || false
        }
      }),
      note: orderDetails.DeliveryComment.value || "",
      unitNumber: address.unitNumber || "",
      manualApnChange: orderDetails.manualApnChange.value,
      promoCode: (orderDetails.promoCode) ? orderDetails.promoCode.value : "",
      isHspSelected: orderDetails.isHspSelected.value,
      estimatedClosingDate: orderDetails.escrowClosingDate.value ? orderDetails.escrowClosingDate.value.toISOString().split('T')[0] : '',
      ubpPropertyType: ubpPropertyType,
      ubpPropertySize: ubpPropertySize,
      ubpHspAddons: ubpHspAddons,
      ubpBuyerName: ubpBuyerName,
      ubpBuyerPhone: ubpBuyerPhone,
      ubpWarrantyType: ubpWarrantyType,
    }
  }

  addOtherRecipient = () => {
    const { otherRecipients, fieldValues } = this.state;
    const offset = otherRecipients.length + 4;
    const newRecipient: EmailToModel = {
      recipientID: otherRecipients.length + 4,
      fieldName: `recipientEmail-${offset}`,
      email: "",
      isOrderConfirm: false,
      isTextField: false
    };

    const fieldKeyValuePairs: FormFieldModel = {};
    fieldKeyValuePairs[`recipientEmail-${offset}`] = {
      name: `recipientEmail-${offset}`,
      value: "",
      type: "email"
    };
    fieldKeyValuePairs[
      `recipientOrderConfirmationCheckbox-${offset}`
    ] = {
      name: `recipientOrderConfirmationCheckbox-${offset}`,
      value: true
    };

    this.setState({
      otherRecipients: [...otherRecipients, newRecipient],
      fieldValues: { ...fieldValues, ...fieldKeyValuePairs }
    });
  };

  updateProperty = (address: Address, countyNum: number, apn: string) => {
    this.defaultOrderFormNhdReport.property_apn_value = apn;
    this.defaultOrderFormNhdReport.nhd_report_address = address;
    this.defaultOrderFormNhdReport.report_county =
      (Counties.find(
        county => county.getValue() === countyNum
      ) as CountyType) || MESSAGE.report_county_default;
  };

  handleBundleConfirmation = () => {
    this.setState({
      showUltimateBundleSelections: true
    });
  }

  handleBundleSelections = (propertyType, propertySize, addOns, warrantyType, buyerName = "", buyerPhone = "") => {
    this.setState({
      ...this.state,
      showUltimateBundleSelections: true,
      ubpPropertyType: propertyType,
      ubpPropertySize: propertySize,
      ubpAddOns: addOns,
      ubpBuyerName: buyerName,
      ubpBuyerPhone: buyerPhone,
      ubpWarrantyType: warrantyType
    },
      () => this.submitHandler(null, true));
  }

  openInNewTab = () => {
    let url = `${ConfigHelper.ZestyBaseMediaUrl}/CA-AHS-Home-Warranty-Plans.pdf`;
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  render() {
    return (
      <>
        <div className={`${this.state.disabledFormDate ? 'disable-order-form-with-opacity' : ''} pre-bds`}>
          <div className="container-fluid order-form mt-15">
            <form
              className="row"
              action="#"
              method="post"
              onSubmit={this.submitHandler}
              noValidate
            >
              <div className="col-lg-7 col-md-7 col-sm-12 col-xs-12">
                <RealEstateAgentInfo
                  fieldValues={this.state.fieldValues}
                  defaultFields={this.defaultRealEstate}
                  defaultValues={this.state.defaultRealEstateDetails}
                  onChangeHandler={this.onChangeHandler}
                  isSubmitted={this.state.isSubmitted}
                />
                <SellerInfo
                  defaultFields={this.defaultSellerInfo}
                  fieldValues={this.state.fieldValues}
                  onChangeHandler={this.onChangeHandler}
                  isSubmitted={this.state.isSubmitted}
                />
                <EscrowInfo
                  defaultFields={this.defaultEscrowInfo}
                  onChangeHandler={this.onChangeHandler}
                  fieldValues={this.state.fieldValues}
                  isSubmitted={this.state.isSubmitted}
                  alwaysRequireClosingDate={false}
                />
                <ReportSelectionInfo
                  fieldValues={this.state.fieldValues}
                  defaultFields={this.defaultReportSelectionInfo}
                  recipientEmails={this.state.recipientEmails}
                  onChangeHandler={this.onChangeHandler}
                  addOtherRecipient={this.addOtherRecipient}
                  otherRecipients={this.state.otherRecipients}
                  onApplyPromocodeHandlerFromOrderForm={this.onApplyPromocodeHandler}
                  promoCodeInfo={this.state.promoCodeInfo}
                  onHspChangeHandler={this.onHspChangeHandler}
                  onBundleGetInfo={() => this.openInNewTab()}
                />
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
                <OrderFormNhdReport
                  onChange={this.updateProperty}
                  defaultFields={this.defaultOrderFormNhdReport}
                  isError={this.state.isConfirmationError}
                  handleApnChangeWithUnit={this.handleManualApnChangeWithUnit}
                />
              </div>
            </form>

            {this.state.hasSubmissionError && (
              <ModalChildren>
                <p>{ERROR_MESSAGES.ORDER_NOT_PLACED}</p>

                <div className="close-button-row">
                  <button className="close-button" onClick={this.closeErrorModal}>
                    Okay
                  </button>
                </div>
              </ModalChildren>
            )}
          </div>
        </div>
        <div className="bds-ahs-web-app">
          {this.state.showUltimateBundleSelections && (
            <UltimateBundleSelectionModal
              onClose={() => this.setState({ showUltimateBundleSelections: false })}
              onConfirmSelections={this.handleBundleSelections}
            />
          )}
        </div>
      </>
    );
  }
}

export default OrderForm;
